import { Map } from 'immutable';

export function clearToken() {
  console.log("clearToken utility");
  localStorage.removeItem('id_token');

}

export function clearUser() {
  console.log("clearUser utility");
  localStorage.removeItem('user');

}

export function getToken() {
  console.log("getToken utility");
  try {

    const idToken = localStorage.getItem('id_token');
    return new Map({ idToken });
  } catch (err) {
    clearToken();
    return new Map();
  }
}

export function getUser() {
  console.log("getUser utility");
  try {
    const user = localStorage.getItem('user');
    return user;

  } catch (err) {
    clearUser();
    return {};
  }
}

export const removeCNPJ = (value) => {
  return value.replace(/\.|\/|\-/g, '')

}

export function timeDifference(givenTime) {
  givenTime = new Date(givenTime);
  const milliseconds = new Date().getTime() - givenTime.getTime();
  const numberEnding = number => {
    return number > 1 ? 's' : '';
  };
  const number = num => (num > 9 ? '' + num : '0' + num);
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000);
    const years = Math.floor(temp / 31536000);
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1);
      const day = number(givenTime.getUTCDate());
      const year = givenTime.getUTCFullYear() % 100;
      return `${day}-${month}-${year}`;
    }
    const days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      if (days < 28) {
        return days + ' day' + numberEnding(days);
      } else {
        const months = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ];
        const month = months[givenTime.getUTCMonth()];
        const day = number(givenTime.getUTCDate());
        return `${day} ${month}`;
      }
    }
    const hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return `${hours} hour${numberEnding(hours)} ago`;
    }
    const minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return `${minutes} minute${numberEnding(minutes)} ago`;
    }
    return 'a few seconds ago';
  };
  return getTime();
}

export function stringToInt(value, defValue = 0) {
  if (!value) {
    return 0;
  } else if (!isNaN(value)) {
    return parseInt(value, 10);
  }
  return defValue;
}
export function stringToPosetiveInt(value, defValue = 0) {
  const val = stringToInt(value, defValue);
  return val > -1 ? val : defValue;
}


export const cpfMask = value => {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export const cnpjMask = value => {
  return value
    .replace(/\D/g, '')// substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{2})(\d)/, '$1.$2')// captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')// captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d{1,4})/, '$1/$2')// captura 3 numeros e coloca uma barra entre eles e o proximo conjunto de 1 a 4 números
    .replace(/(\d{4})(\d{1,2})/, '$1-$2')// captura 4 numeros e coloca um traço entre eles e o proximo conjunto de 1 a 2 números
    .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export const cnpjMaskValidation = value => {
  const b = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
  const c = String(value).replace(/[^\d]/g, '');
  //Validando se existem 14 Caracteres
  if (c.length !== 14)
    return false;
  //Validando se os 14 Caracteres são de fato Números
  if (/0{14}/.test(c))
    return false;
  //Verificando o primeiro digito de conferencia do cnpj
  for (var i = 0, n = 0; i < 12; n += c[i] * b[++i]);
  if (c[12] !== (((n %= 11) < 2) ? 0 : 11 - n))
    return false;
  //Verificando o segundo digito de conferencia do cnpj
  for (let i = 0, n = 0; i <= 12; n += c[i] * b[i++]);
  if (c[13] !== (((n %= 11) < 2) ? 0 : 11 - n))
    return false;
  //CNPJ valido;
  return true
}

export const financeiroMask = value => {
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

}

export const getNewAcceptKey = (list = []) => {
  const checkKeyAlreadyUsed = (key, l) => {
    const x = l.filter(f => f.key === key)
    if (x.length > 0) {
      return checkKeyAlreadyUsed(key + 1, l)
    } else {
      return key;
    }
  }
  const len = list.length;
  const newKey = checkKeyAlreadyUsed(len, list);
  return newKey;
}

export function checkIfValidUUID(str) {
  // Regular expression to check if string is a valid UUID
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  return regexExp.test(str);
}